<template>
  <section>
    <b-container fluid>
      <b-row>
        <b-col xl="8" lg="8" cols="12" :key="count">
          <b-card-actions
              class="list-card"
              ref="stok-listesi"
              @refresh="refreshStop('list')"
          >
            <b-row class="mt-50">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.COLORS_GET.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block card-title">Kullanıcı Listesi</h4>
                </b-col>
              </div>
            </b-row>
            <table class="table table-hover table-sm mt-5" :class="width < 1500 ? 'table-responsive':''">
              <thead>
              <tr>
                <th :style="{backgroundColor: $store.getters.COLORS_GET.bgInfo +'!important',color:'white!important',border:'none!important'}"
                    scope="col"
                >Kullanıcı Adı
                </th>
                <th :style="{backgroundColor: $store.getters.COLORS_GET.bgInfo +'!important',color:'white!important',border:'none!important'}"
                    scope="col"
                >Hesap Sahibi
                </th>
                <th :style="{backgroundColor: $store.getters.COLORS_GET.bgInfo +'!important',color:'white!important',border:'none!important'}"
                    scope="col"
                >Modül
                </th>
                <th :style="{backgroundColor: $store.getters.COLORS_GET.bgInfo +'!important',color:'white!important',border:'none!important'}"
                    scope="col"
                >Telefon
                </th>
                <th :style="{backgroundColor: $store.getters.COLORS_GET.bgInfo +'!important',color:'white!important',border:'none!important'}"
                    scope="col"
                >E-Posta
                </th>
                <th :style="{backgroundColor: $store.getters.COLORS_GET.bgInfo +'!important',color:'white!important',border:'none!important'}"
                    scope="col"
                >Yetki Türü
                </th>
                <th :style="{backgroundColor: $store.getters.COLORS_GET.bgInfo +'!important',color:'white!important',border:'none!important'}"
                    scope="col"
                >Durum
                </th>
                <th :style="{backgroundColor: $store.getters.COLORS_GET.bgInfo +'!important',color:'white!important',border:'none!important'}"
                    scope="col"
                ></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="user in userList" :key="user.userID">
                <td>{{ user.userName }}</td>
                <td>{{ user.isim }}</td>
                <td>{{ user.modul }}</td>
                <td>{{ user.tel }}</td>
                <td>{{ user.ePosta }}</td>
                <td>
                  <b-badge :variant="user.level == 1? 'success':'primary'">
                    {{ user.level == 1 ? 'Yönetici' : 'Personel' }}
                  </b-badge>
                </td>
                <td>
                  <b-form-checkbox
                      checked="true"
                      class="custom-control-success"
                      name="check-button"
                      switch
                      :value="user.pasif == 1 ? false : true"
                      @change="onlineSet(user.userID)"
                  >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon"/>
                      </span>
                    <span class="switch-icon-right">
                        <feather-icon icon="XIcon"/>
                      </span>
                  </b-form-checkbox>
                </td>
                <td>
                  <b-button
                      variant="gradient-warning"
                      class="btn-icon rounded-circle mr-50"
                      size="sm"
                      v-b-modal.sifre-degis
                      @click="updateID = user.userID"
                      :style="{backgroundColor:$store.getters.COLORS_GET.bgWarning+'!important',border:'none'}"
                  >
                    <font-awesome-icon icon="fa-solid fa-lock"/>
                  </b-button>
                  <b-button
                      variant="gradient-info"
                      class="btn-icon rounded-circle mr-50"
                      size="sm"
                      @click="updateStepOne(user)"
                      :style="{backgroundColor:$store.getters.COLORS_GET.bgInfo+'!important',border:'none'}"
                  >
                    <font-awesome-icon icon="fa-solid fa-pen"/>
                  </b-button>
                  <b-button
                      variant="gradient-danger"
                      class="btn-icon rounded-circle"
                      size="sm"
                      @click="remove(user.userID)"
                      :style="{backgroundColor:$store.getters.COLORS_GET.bgDanger+'!important',border:'none'}"
                  >
                    <font-awesome-icon icon="fa-solid fa-trash-can"/>
                  </b-button>
                </td>
              </tr>
              </tbody>
            </table>
            <hr/>
            <b-row class="d-flex align-items-center mt-1">
              <b-col cols="12">
                <p class="text-muted">Toplam {{ toplamKayit }} Kayıt bulundu</p>
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>
        <b-col xl="4" lg="4" cols="12">
          <b-card-actions
              class="list-card"
              ref="stok-listesi"
              @refresh="refreshStop('list')"
          >
            <b-row class="mt-50">
              <div class="card-header-row"
                   :style="{backgroundColor: !edit ? $store.getters.COLORS_GET.cardTitle : 'orange'}"
              >
                <b-col cols="12">
                  <h4 class="text-white d-inline-block card-title">{{
                      !edit ? 'Kullanıcı Kaydı' : oldName + ' Adlı Kullanıcı Düzenleniyor'
                    }}</h4>
                </b-col>
              </div>
            </b-row>
            <b-form @submit.prevent="save" class="mt-5">
              <b-row>
                <b-col cols="12">
                  <b-form-group
                      label="Kullanıcı Türü"
                      label-cols-md="4"
                  >
                    <v-select :components="{ OpenIndicator, Deselect }" v-model="user.tur"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title"
                              :options="user.turOption"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                      label="Modül"
                      label-cols-md="4"
                  >
                    <v-select :components="{ OpenIndicator, Deselect }" v-model="user.modul"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title"
                              :options="user.modulOptions"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                      label="Kullanıcı Adı"
                      label-cols-md="4"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text v-if="!edit">
                        <font-awesome-icon icon="fa-solid fa-circle-user"/>
                      </b-input-group-prepend>
                      <b-form-input
                          placeholder="Kullanıcı Adı"
                          v-model="user.kullaniciAdi"
                          :disabled="edit"
                          autocomplete="off"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12" v-if="!edit">
                  <b-form-group
                      label="Şifre"
                      label-cols-md="4"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <font-awesome-icon icon="fa-solid fa-lock"/>
                      </b-input-group-prepend>
                      <b-form-input
                          placeholder="Şifre"
                          autocomplete="off"
                          v-model="user.sifre"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                      label="Personel İsmi"
                      label-cols-md="4"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <font-awesome-icon icon="fa-solid fa-user"/>
                      </b-input-group-prepend>
                      <b-form-input
                          placeholder="adı soyadı"
                          v-model="user.adSoyad"
                          autocomplete="off"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                      label="Telefon"
                      label-cols-md="4"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <font-awesome-icon icon="fa-solid fa-phone"/>
                      </b-input-group-prepend>
                      <b-form-input
                          placeholder="telefon"
                          v-model="user.telefon"
                          autocomplete="off"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                      label="E-Posta"
                      label-cols-md="4"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <font-awesome-icon icon="fa-solid fa-envelope"/>
                      </b-input-group-prepend>
                      <b-form-input
                          placeholder="e-posta"
                          v-model="user.ePosta"
                          autocomplete="off"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col offset-md="4" class="d-flex justify-content-end">
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="success"
                      v-if="!edit"
                      :style="{backgroundColor:$store.getters.COLORS_GET.bgSuccess+'!important',border:'none'}"
                  >
                    <font-awesome-icon icon="fa-solid fa-floppy-disk"/>
                    Kaydet
                  </b-button>
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mr-50"
                      @click="updateStepSecond"
                      v-if="edit"
                      :style="{backgroundColor:$store.getters.COLORS_GET.bgSuccess+'!important',border:'none'}"
                  >
                    <font-awesome-icon icon="fa-solid fa-floppy-disk"/>
                    Güncelle
                  </b-button>
                  <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-danger"
                      v-if="edit"
                      @click="editCancel"
                  >
                    Vazgeç
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-card-actions>
        </b-col>
      </b-row>
    </b-container>
    <b-modal
        id="sifre-degis"
        ok-title="Kaydet"
        modal-class="modal-primary"
        centered
        :title="`Şifre Değiştir ${oldName}`"
        cancel-variant="outline-secondary"
        cancel-title="Vazgeç"
        @ok="sifreDegis"
        @hidden="updateID = ''"
    >
      <b-form-group
          label="Şifre"
          label-for="basicInput"
      >
        <b-form-input
            id="basicInput"
            v-model="changeSifre"
        />
      </b-form-group>
    </b-modal>
  </section>
</template>
<script>
import {
  BAvatar,
  BContainer,
  BModal,
  BFormSelect,
  BInputGroupAppend,
  BPagination,
  VBTooltip,
  BBadge,
  BTable,
  BRow, BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BForm,
  BButton,
  BCardHeader,
  BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import 'vue-search-select/dist/VueSearchSelect.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import vSelect from 'vue-select'
import { useWindowSize } from '@vueuse/core'

export default {
  setup() {
    const {
      width,
    } = useWindowSize()
    return {
      width,
    }
  },
  components: {
    vSelect,
    BAvatar,
    BContainer,
    BModal,
    BFormSelect,
    BInputGroupAppend,
    BPagination,
    BBadge,
    BTable,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardActions,
  },
  data() {
    return {
      changeSifre: '',
      count: 1,
      edit: false,
      updateID: '',
      oldName: '',
      toplamKayit: '',
      user: {
        ePosta: '',
        telefon: '',
        adSoyad: '',
        sifre: '',
        kullaniciAdi: '',
        tur: {
          title: 'Personel',
          key: 2,
        },
        turOption: [
          {
            title: 'Yönetici',
            key: 1,
          },
          {
            title: 'Personel',
            key: 2,
          },
        ],
        modul: {
          title: 'Büro',
          key: 'buro',
        },
        modulOptions: [
          {
            title: 'Büro',
            key: 'buro',
          },
          {
            title: 'Otel',
            key: 'otel',
          },
        ],
      },
      Deselect: {
        render: (createElement) => createElement('span', ''),
      },
      OpenIndicator: {
        render: (createElement) => createElement('span', ''),
      },
    }
  },
  computed: {
    userList() {
      return store.getters.USER_LIST_GET
    },
  },
  watch: {
    edit(val) {
      if (val == false) {
        this.name = ''
      }
    },
  },
  methods: {
    sifreDegis() {
      this.$store.dispatch('sifreDegis', {
        userID: this.updateID,
        sifre: this.changeSifre,
      })
          .then((res, position = 'bottom-right') => {
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                text: mesaj,
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            }, { position })
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
      this.count += 1
    },
    editCancel() {
      this.edit = false
      this.oldName = ''
      this.updateID = ''
      this.reset()
    },
    onlineSet(id) {
      this.$store.dispatch('userStatus', id)
          .then((res, position = 'bottom-right') => {
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                text: mesaj,
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            }, { position })
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
      this.count += 1
    },
    updateStepOne(user) {
      this.edit = true
      this.oldName = user.isim
      this.updateID = user.userID
      this.user.kullaniciAdi = user.userName
      this.user.tur = user.level == 1 ? {
        title: 'Yönetici',
        key: 1,
      } : {
        title: 'Personel',
        key: 2,
      }
      this.user.modul = user.modul == 'buro' ? {
        title: 'Büro',
        key: 'buro',
      } : {
        title: 'Otel',
        key: 'otel',
      }
      this.user.adSoyad = user.isim
      this.user.telefon = user.tel
      this.user.ePosta = user.ePosta
    },
    updateStepSecond() {
      const t = this
      store.dispatch('userUpdate', {
        userID: t.updateID,
        tur: t.user.tur.key,
        adSoyad: t.user.adSoyad,
        telefon: t.user.telefon,
        ePosta: t.user.ePosta,
        modul: t.user.modul.key,
      })
          .then((res, position = 'bottom-right') => {
            this.edit = false
            this.updateID = ''
            this.oldName = ''
            this.reset()
            const mesaj = store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    remove(userID) {
      store.dispatch('userRemove', userID)
          .then((res, position = 'bottom-right') => {
            if (res.result.status == 200) {
              store.dispatch('userList')
                  .then(res => {
                    this.toplamKayit = Object.keys(res.userList).length
                  })
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                }, { position })
              }
            } else {
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    save() {
      const t = this
      store.dispatch('userSave', {
        tur: t.user.tur.key,
        kullaniciAdi: t.user.kullaniciAdi,
        adSoyad: t.user.adSoyad,
        sifre: t.user.sifre,
        telefon: t.user.telefon,
        ePosta: t.user.ePosta,
        modul: t.user.modul.key,
      })
          .then((res, position = 'bottom-right') => {
            if (res.result.status == 200) {
              store.dispatch('userList')
                  .then(res => {
                    this.toplamKayit = Object.keys(res.userList).length
                  })
              this.reset()
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                }, { position })
              }
            } else {
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'list'].showLoading = false
      }, 1000)
    },
    reset() {
      const th = this
      th.user.kullaniciAdi = ''
      th.user.tur = {
        title: 'Personel',
        key: 2,
      }
      th.user.modul = {
        title: 'Büro',
        key: 'buro',
      }
      th.user.adSoyad = ''
      th.user.sifre = ''
      th.user.telefon = ''
      th.user.ePosta = ''
    },
  },
  created() {
    store.dispatch('userList')
        .then(res => {
          this.toplamKayit = Object.keys(res.userList).length
        })
  },
  beforeDestroy() {
    store.commit('USER_LIST_ACTION', 'remove')
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
